body {
  font-family: Raleway, sans-serif !important;
  background-color: #f9fafb;
}

input, textarea{
  outline-color: #166534;
}

.font-playfair {
  font-family: Playfair Display, sans-serif !important;
}

.banner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
